body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: lightgray !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  line-height: 1;
}

.welcome-text-header {
  font-family: 'Rubik Vinyl', cursive;
  font-weight: bolder;
  font-size: xx-large;
}

.welcome-text {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-style: italic;
  font-size: x-large;
}

.header {
  background-color: whitesmoke;
  color: gray;
}

.footer {
  background-color: whitesmoke;
  /* position: fixed;
  bottom: 0px;
  height: 20px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030 */
}

footer {
  /* height: 20px; */
  font-size: 13px;
  color: darkslategrey;
  text-align: center;
  font-family: 'serif';
}

footer a {
  color: darkolivegreen;
  font-size: 13px;
  text-decoration: none;
}

footer a:hover {
  color: darkred;
  font-size: 13px;
  text-decoration: underline;
}